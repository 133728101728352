var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        { attrs: { label: "name" } },
        [
          _c("br"),
          _c(
            "el-tag",
            {
              staticStyle: { "font-size": "20px" },
              attrs: { type: "success", size: "medium", effect: "plain" }
            },
            [_vm._v(_vm._s(_vm.user.name))]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Old Password" } },
        [
          _c("el-input", {
            model: {
              value: _vm.old_password,
              callback: function($$v) {
                _vm.old_password = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "old_password"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "New Password" } },
        [
          _c("el-input", {
            model: {
              value: _vm.new_password,
              callback: function($$v) {
                _vm.new_password = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "new_password"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Retry New Password" } },
        [
          _c("el-input", {
            model: {
              value: _vm.retry_new_password,
              callback: function($$v) {
                _vm.retry_new_password =
                  typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "retry_new_password"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.updatepassword } },
            [_vm._v("updates")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }