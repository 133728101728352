<template>
  <el-form>
    <el-form-item label="name">
      <br/>
      <el-tag type='success' size="medium" effect="plain" style="font-size: 20px;">{{user.name}}</el-tag>
    </el-form-item>
    <el-form-item label="Old Password">
      <el-input v-model.trim="old_password" />
    </el-form-item>
    <el-form-item label="New Password">
      <el-input v-model.trim="new_password" />
    </el-form-item>
    <el-form-item label="Retry New Password">
      <el-input v-model.trim="retry_new_password" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="updatepassword">updates</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { ResetPersonalPassword }from '@/api/admin_manager'
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
        }
      }
    }
  },
  data() {
    return {
        old_password:'',
        new_password:'',
        retry_new_password:''
    }
  },
  methods: {
    async updatepassword() {
        var payload ={}
        payload['old_password'] = this.old_password,
        payload['new_password'] = this.new_password,
        payload['retry_new_password'] = this.retry_new_password;
        await ResetPersonalPassword(payload).then(async (resp)=>{
          await this.$message({
              type: resp.success==true?'success':'error',
              message: resp.message
          })
          if(resp.success==true){
              this.old_password = '',
              this.new_password = '',
              this.retry_new_password = ''
              await this.$store.dispatch('user/logout')
              console.log(`/login?redirect=${this.$route.fullPath}`)
              await this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          }
        })
    },
  }
}
</script>
