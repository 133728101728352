<template>
  <div class="block">
    <div class="radio" style="margin:10px 0;">
        order：
        <el-radio-group v-model="reverse_log_record_time">
        <el-radio :label="true">desc</el-radio>
        <el-radio :label="false">asc</el-radio>
        </el-radio-group>
    </div>
    <el-timeline :reverse="reverse_log_record_time">
        <!-- {{id}} -->
        <!-- {{log_record_time}} -->
      <el-timeline-item 
      v-for="(item,index) of log_record_time" 
      :key="index" 
      :timestamp="item.created_at">
        <el-card>
          <h4>{{ item.name }} / {{ item.roles_name}}</h4>
          <p>{{ item.description }}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLogRecord } from '@/api/log_manager'
export default {
  name: "logrecord",
  data() {
    return {
      log_record_time: [],
      reverse_log_record_time: false
    }
  },
  computed: {
    ...mapGetters([
      'id'
    ])
  },
  mounted() {
    this.getLogRecord(1)
  },
  methods: {
      getLogRecord(page) {
          var payload = ''
          payload += page + '?admin_id=' + this.id
          getLogRecord(payload).then((resp)=>{
              this.log_record_time = resp.data.list
          })
      }
  },
}
</script>
